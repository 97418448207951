import React, { useEffect, useContext } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import Header from './Header';


// import appConfig from '../appConfig';
import getHost from '../helpers/gethost';
import LoginComponent from './LoginComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setSmsCheckBox } from '../redux/slices/userSlice';
import { useConfigData } from '../Context/ConfigProvider';

const canonicalHref = `https://${getHost()}/login`;

export const Login = () => {
  const router = useRouter();
  const appConfig = useConfigData();
  const {
    query: { redirect, friends }
  } = router;

  // const { state: { seoData = {} } = {} } = useContext(appContext);
  const seoData = useSelector(state => state.other.seoData) || {}
  const dispatchRedux = useDispatch()

  useEffect(() => {
    return () => {
      // dispatch({ type: 'setSmsCheckBox', payload: { smsCheckbox: false } });
      // dispatch({ type: 'setSmsCheckBox', payload: { smsMessage: false } });
      dispatchRedux(setSmsCheckBox({ smsCheckbox: false }))
    };
  }, []);

  const { site_info: siteInfo = {} } = seoData;

  return (
    <>
      <Head>
        <title>{`Login/Signup | ${siteInfo.meta_title_description || appConfig.META_TITLE_DESCRIPTION}`}</title>
        <meta
          name="description"
          content="Login to your secure customer account and experience the fastest-growing premium weed delivery service in California."
        />
        <link rel="canonical" href={canonicalHref} />
      </Head>

      <Header
        h1Title
        minimalNavbar
        showLogin={false}
        backPath
        pageName="Login/Signup"
        hasWhiteBg
        hideCartIcon
        hasShadow
        hideTeslaCountDown
      />

      <div className="login container-fluid">
        <div className="right-sec">
          <div className="img-container text-center">
            {friends ? (
              <img
                loading="lazy"
                className="img-fluid"
                src="/static/images/friends-family-color.jpeg"
                alt={`${appConfig.APP_NAME}-logo`}
              />
            ) : (
              <img loading="lazy" className="img-fluid" src={appConfig.LOGO} alt={`${appConfig.APP_NAME}-logo`} />
            )}
          </div>

          <LoginComponent redirectTo={redirect || '/'} />
        </div>
      </div>
    </>
  );
};

export default Login;
